<template>
    <div>
        <h1>FileList</h1>

        <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="index" width="100">
            </el-table-column>
            <el-table-column prop="_id" label="id" width="120"> </el-table-column>

            <el-table-column prop="adress" label="地址" width="120">
            </el-table-column>
            <el-table-column prop="url" label="url" width="120"> </el-table-column>
            <el-table-column prop="fileName" label="备注" width="120">
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template v-on="touchmove" slot-scope="scope">
                    <el-button @click="open(scope.row)" type="text" size="small">备注</el-button>
                    <el-button type="text" size="small" @click="deleteData(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
    
<script>
import url from "@/serviceAPI.config.js";
import axios from "axios";
export default {
    data() {
        return {
            tableData: [],
            rowData: {},
        };
    },
    methods: {
        // 备注文件名
        open(row) {
            this.rowData = row;
            this.$prompt("请输入文件名", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(({ value }) => {
                    this.rowData.fileName = value;
                    this.addInfo();
      
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消输入",
                    });
                });
        },
        // 获取文件列表
        getdata() {
            axios({
                url: url.fileList,
                method: "get",
            })
                .then((response) => {
                    this.tableData = response.data.data.reverse();
                })
                .catch((error) => {
                    console.log(error);
                    // ！！！出现错误怎么优化，暂时用刷新
                    // location.reload();
                    // 重新调用函数
                    // this.getdata()
                });
        },
        // 删除文件
        async deleteData(row) {
            console.log(row);
            this.$confirm(`确定移除 ${row.adress}？`, {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
            }).then(async (res) => {
                console.log(row._id);
                if (res == "confirm") {
                    axios({
                        url: url.fileList,
                        method: "delete",
                        data: {
                            data: row,
                        },
                    })
                        .then((response) => {
                            this.tableData = response.data.data;
                            this.getdata();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.getdata();
                        });
                }
            });
        },
        // 调用修改文件接口
        addInfo() {
            console.log("addInfo");
            axios({
                url: url.fileList,
                method: "post",
                data: this.rowData
            })
                .then((Response) => {
                    console.log(Response);
                    if (Response.data.code == 200) {
                        this.$message({
                        type: "success",
                        message: "修改成功",
                    });
                    } else {
                        this.$message({
                        type: "success",
                        message: "修改失败",
                    });
                    }
                })
                .catch((error) => {
                    this.$message({
                        type: "success",
                        message: "修改成功",
                    });
                    console.log(error);
                    // alert("修改博客失败error");
                });
        },

    },
    created() {
        this.getdata();
    },
};
</script>
    
<style scoped></style>
    